import React, { useState } from "react";
import { Stack, Box } from "@mui/material";
import CustomButton from "./CustomButton";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { env } from "../env"
import api from "../Services/api";

const MyCards = ({ groups = [] }) => {
  const [itemsPerPage] = useState(12);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(groups.length / itemsPerPage);

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const showToastMessage = (message) => {
    toast(message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const renderPagination = () => {
    return (
      <nav aria-label="Page navigation">
        <ul className="pagination flex-wrap">
          <CustomButton onClick={handlePrevious} isVisible={currentPage !== 1}>
            Anterior
          </CustomButton>
          <CustomButton
            onClick={handleNext}
            isVisible={currentPage !== totalPages}
          >
            Próxima
          </CustomButton>
        </ul>
      </nav>
    );
  };

  const itemsToRender = groups.slice(startIndex, endIndex);

  const removeGroup = async (id) => {
    try {
      const confirmation = window.confirm('Tem certeza que deseja excluir o grupo selecionado grupo? Essa ação não pode ser desfeita.');
      if (confirmation) {
        const response = await api.delete(`/group/${id}`);
        if (response.status === 200) {
          window.location.reload();
          showToastMessage("Grupo removido com sucesso!");
        }
      } else {
        showToastMessage("Exclusão cancelada!");
      }
    } catch (error) {
      console.log(`Erro ao excluir um grupo -> ${error}`);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="section-groups row mt-5 pb-3" height="auto !important;">
        {itemsToRender.map((group) => (
          <div
            className="col-12 col-md-6 col-lg-4 mb-4 col-group"
            key={group.group_id}
          >
            <div className="card group vip">
              <img
                className="card-img-top"
                alt={group.group_name}
                src={
                  (group.group_image.includes("http") || group.group_image.includes("https")) ?
                    group.group_image :
                    `${env.REACT_APP_IMG_URL}/${group.group_image}`
                }
                loading="lazy"
              />
              <div className="card-body">
                <span className="card-category">{group.category_description}</span>
                <h5 className="card-title">{group.group_name}</h5>
                <p className="card-text">{group.group_description}</p>
                <a
                  href="#"
                  onClick={async () => await removeGroup(group.group_id)}
                  className="btn btn-success btn-block stretched-link font-weight-bold"
                >
                  REMOVER GRUPO
                </a>
              </div>
              {group.group_vip == 1 && (
                <div className="vip-star">
                  <img
                    src="https://gruposwhats.app/images/star.png"
                    height="40"
                    alt="VIP"
                  />
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      <Stack direction="column">
        <Box
          sx={{
            letterSpacing: "0.025em",
            textAlign: "center",
            fontStyle: "normal",
            zIndex: "20",
          }}
        >
          {renderPagination()}
        </Box>
      </Stack>
    </>
  );
};

export default MyCards;
