import React, { useEffect, useState } from "react";

import PlansText from "./Components/PlansText";
import Cards from "./Components/Cards";
import Categories from "./Components/Categories";
import api from "./Services/api";
import Group from "./Components/Group";

const Page = ({ TextTypography, setCategorias }) => {
  const [categories, setCategories] = useState([]);
  const [groups, setGroups] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedGrupo, setSelectedGrupo] = useState(null);
  const [groupsTemporary, setGroupsTemporary] = useState([]);
  const [ads, setAds] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get("/ads");
        setAds(response.data.ads);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get("/groups");
        setGroupsTemporary(response.data.groups);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get("/categorias");
        setCategorias(response.data.data);
        setCategories(response.data.data);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (!selectedCategory) {
          setGroups(groupsTemporary);
        } else {
          const filteredGroups = groupsTemporary.filter(
            (group) => group.category_description === selectedCategory
          );
          setGroups(filteredGroups);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [selectedCategory, groupsTemporary]);

  return (
    <>
      {categories && (
        <Categories
          categories={categories}
          setSelectedCategory={setSelectedCategory}
        />
      )}
      {selectedGrupo && <Group group={selectedGrupo} />}
      <PlansText
        TextTypography={TextTypography}
        selectedCategory={selectedCategory}
      />
      {groups && <Cards groups={groups} setSelectedGrupo={setSelectedGrupo} ads={ads} />}
    </>
  );
};

export default Page;
