import React, { useState, useRef, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getData } from "../Services/localStorage";
import api from "../Services/api";
import { env } from "../env"

const FormGroup = ({ Categorias }) => {
  const [groupInfo, setGroupInfo] = useState({});
  const [rules, setRules] = useState([]);
  const [groupName, setGroupName] = useState("Nome do Grupo");
  const [description, setDescription] = useState("Descrição");
  const [optionalDescription, setOptionalDescription] = useState("");
  const [link, setLink] = useState("");
  const [email, setEmail] = useState("");
  const [category, setCategory] = useState("Categoria");
  const [categoryId, setCategoryId] = useState(null);
  const [checkedRules, setCheckedRules] = useState([]);
  const [firstStep, setFirstStep] = useState(true);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [image, setImage] = useState(`${env.REACT_APP_API_URL}/public/sem-foto.jpg`);
  const [selectedFile, setSelectedFile] = useState([]);
  const fileInputRef = useRef();

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get("/regras");
        setRules(response.data.rules.rules);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const handleRuleChange = (e) => {
    const { value } = e.target;
    const ruleId = Number(value);
    setCheckedRules((prevState) =>
      prevState.includes(ruleId)
        ? prevState.filter((item) => item !== ruleId)
        : [...prevState, ruleId]
    );
  };

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
    const category = Categorias.find((item) => item.description === e.target.value);
    setCategoryId(category.id);
  };

  const onFinish = () => {
    setImage(`${env.REACT_APP_API_URL}/public/sem-foto.jpg`);
    setSelectedFile([]);
    setGroupName("Nome do Grupo");
    setDescription("Descrição");
    setOptionalDescription("");
    setLink("");
    setEmail("");
    setCategory("Categoria");
    setCategoryId(null);
    setCheckedRules([]);
    setFirstStep(true);
    setIsReadOnly(false);
    //window.location.reload();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      formData.append('name', groupName);
      formData.append('description', description);
      formData.append('rulesaditional', optionalDescription);
      formData.append('invite', link);
      formData.append('email', email);
      formData.append('image', image);
      formData.append('category', categoryId);
      formData.append('rulesids', checkedRules);
      formData.append('userid', getData("userToken"));
      selectedFile.forEach((imagem) => {
        formData.append("photo", imagem);
      });

      const response = await api.post("/group", formData);
      showToastMessage(response.data.group.message);
      onFinish();
    } catch (error) {
      showToastMessage(error);
    }

  };

  const handleValidateGroupLink = async () => {
    if (!link) {
      showToastMessage("Informe o link do grupo!");
      return;
    }
    const verify = await api.get("/groupbyinvite/" + encodeURIComponent(link));
    if (verify.data.group && verify.data.group.length > 0) {
      showToastMessage("Esse convite já está cadastrado!");
      return;
    }
    const response = await api.get("/groupInfo/" + link.split("/").pop());
    setGroupInfo(response.data.groupInfo);
    setGroupName(response.data.groupInfo.subject);
    setDescription(response.data.groupInfo.desc);
    setImage(response.data.groupInfo.image);
    setFirstStep(!firstStep);
    setIsReadOnly(true);
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileInputChange = (event) => {
    setSelectedFile(Array.from(event.target.files));
    setImage(URL.createObjectURL(event.target.files[0]));
  };

  const showToastMessage = (message) => {
    toast(message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  return (
    <>
      <ToastContainer />
      <h1 className="section-title">Enviar Grupo</h1>
      <p className="section-description">
        Envie o seu grupo e comece a receber novos integrantes rapidamente. Mas
        atenção, cumpra todas as regras para que o seu grupo seja aceito com
        maior rapidez.
      </p>
      <div className="row mt-5">
        <div className="d-none d-md-block col-md-4">
          <div className="card group vip">
            <img
              className="card-img-top"
              alt="imagem do grupo"
              src={`${image}`}
              loading="lazy"
            />
            <div className="card-body">
              <span className="card-category" id="prevCat">
                {category}
              </span>
              <h5 className="card-title" id="prevName">
                {groupName}
              </h5>
              <p className="card-text" id="prevDesc">
                {description}
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-8">
          <div className="box">
            <form
              onSubmit={handleSubmit}
              id="create-group"
              className={firstStep ? "first-step" : ""}
            >
              <div className="form-group">
                <div id="sfImage" className="sfImage">
                  <span id="sfImgPlaceholder" className="sfImgPlaceholder">
                    <button type="button" onClick={handleButtonClick}>Selecionar imagem</button>
                  </span>
                </div>
                <input
                  id="groupImage"
                  type="file"
                  accept="image/*"
                  name="image"
                  ref={fileInputRef}
                  onChange={handleFileInputChange}
                  style={{ display: "none" }}
                />
                <div className="form-control d-none "></div>
              </div>
              <div className="form-group">
                <label>Nome do Grupo</label>
                <input
                  id="groupName"
                  className="form-control form-control-lg"
                  type="text"
                  name="name"
                  value={groupName}
                  required=""
                  onChange={(e) => setGroupName(e.target.value)}
                />
                <small className="form-text text-muted">
                  Use apenas letras e números. Evite o uso de ícones e
                  caracteres especiais.
                </small>
              </div>

              <div className="form-group">
                <label>Descrição</label>
                <textarea
                  id="groupDesc"
                  className="form-control form-control-lg"
                  name="description"
                  required=""
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                ></textarea>
                <small className="form-text text-muted">
                  A descrição deve explicar o objetivo e o tema do seu grupo.
                </small>
              </div>

              <div className="form-group groupLink">
                <label>Link do Grupo</label>
                <input
                  id="groupLink"
                  className="form-control form-control-lg"
                  type="text"
                  name="url"
                  value={link}
                  readOnly={isReadOnly}
                  onChange={(e) => setLink(e.target.value)}
                />
                <div
                  className="invalid-feedback groupLinkError d-none"
                  role="alert"
                >
                  <strong>Digite um link válido</strong>
                </div>
              </div>

              <div className="form-group form-group-email ">
                <label>Seu e-mail</label>
                <input
                  id="groupEmail"
                  className="form-control form-control-lg"
                  type="email"
                  name="email"
                  value={email}
                  required
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div className="form-group">
                <label>Categoria:</label>
                <select
                  className="form-control form-control-lg"
                  value={category}
                  onChange={(e) => handleCategoryChange(e)}
                  required
                >
                  <option value="">Selecione uma categoria</option>
                  {Categorias.map((category) => (
                    <option key={category.id} value={category.description}>
                      {category.description}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group">
                <label>Regras:</label>
                <div className="rules">
                  {rules.map((rule, index) => (
                    <div className="rule" key={index}>
                      <input
                        id={`rule-${rule.id}`}
                        name="rules[]"
                        type="checkbox"
                        value={Number(rule.id)}
                        checked={checkedRules.includes(Number(rule.id))}
                        onChange={handleRuleChange}
                      />
                      <label
                        htmlFor={`rule-${rule.id}`}
                        className="stretched-link"
                      >
                        {rule.description}
                      </label>
                    </div>
                  ))}
                </div>
                <br />
                <textarea
                  className="form-control form-control-lg"
                  value={optionalDescription}
                  onChange={(e) => setOptionalDescription(e.target.value)}
                />
                <small className="form-text text-muted">
                  <strong>Opcional</strong>: Você pode escrever mais regras do
                  seu grupo, uma por linha.
                </small>
              </div>
              <div className="form-group sendLink mb-0">
                <small className="d-none mb-3 text-danger text-center invalid-group"></small>
                <a
                  href="#"
                  className="sfSubmit btn btn-gwa btn-lg font-weight-bold"
                  id="send-link"
                  style={{
                    width: "100%",
                    textAlign: "center",
                  }}
                  onClick={handleValidateGroupLink}
                >
                  <span className="text">Validar link do grupo</span>
                  <div className="spinner-border text-light spinner d-none"></div>
                </a>
              </div>
              <div className="form-group mb-0">
                <input
                  className="sfSubmit sfSubmit2 btn btn-lg btn-block btn-gwa"
                  type="submit"
                  value="Enviar Grupo"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormGroup;
