import React, { useEffect } from "react";
import { env } from "../env"
import { format } from 'date-fns';
import api from "../Services/api";

const Group = ({ group }) => {
  useEffect(() => {

    (async () => {
      try {
        const response = await api.get("/groupaccess/" + group.group_id);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [group]);

  return (
    <>
      <section className="section section-group pt-5" height="auto !important;">
        <div className="container" height="auto !important;">
          <h1 className="section-title">{group.group_name}</h1>
          <div className="row mt-4 mb-5" height="auto !important;">
            <div
              className="col-12 col-md-6 col-lg-4 col-lg-6 col-xl-6 mb-4 col-group"
              height="auto !important;"
            >
              <div className="card group vip">
                <img
                  className="card-img-top"
                  alt={group.group_name}
                  src={
                    (group.group_image.includes("http") || group.group_image.includes("https")) ?
                      group.group_image :
                      `${env.REACT_APP_IMG_URL}/${group.group_image}`
                  }
                  loading="lazy"
                />
                <div className="card-body">
                  <span className="card-category">{group.category_description}</span>
                  <h5 className="card-title">{group.group_name}</h5>
                  <p className="card-text">{group.group_description}</p>
                  <a
                    href={group.group_invite}
                    className="btn  btn-success  btn-block stretched-link font-weight-bold  check-group-tmp "
                    id={group.group_id}
                  >
                    Entrar no Grupo
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 mb-4" id="infos">
              <div className="more-infos">
                <a
                  href="/"
                  className="category"
                >{`Grupo de Whatsapp de ${group.category_description}`}</a>
                <p className="hits">
                  O grupo {group.group_name} já teve {group.group_access} acessos.{" "}
                  <a href="https://gruposwhats.app/send-group">
                    Envie o seu grupo de whatsapp
                  </a>{" "}
                  também e comece a receber novos integrantes!
                </p>
                <div className="rules">
                  <b>Regras do grupo</b>
                  <small>Leia todas as regras desse grupo de whatsapp:</small>
                  <ol>
                    {
                      group.group_rules_description.split(",").map((rule, index) => {
                        return <li key={index}>{rule}</li>
                      })
                    }
                  </ol>
                </div>
                <p className="last-check mb-0">
                  Antes de acessar o grupo de whatsapp {group.group_name}, esteja
                  ciente que você precisa estar de acordo com todas as regras do
                  grupo. É importante dizer também que este é um grupo
                  independente, sem nenhuma relação com o nosso site e o único
                  responsável por ele é o administrador do grupo.
                  O {group.group_name} faz parte da categoria de {group.category_description} e já
                  foi acessado {group.group_access} vezes através do nosso site.<br></br>
                  Este grupo de whatsapp foi enviado em {format(new Date(group.group_createdat), "dd/MM/yyyy")} e verificado
                  pela última vez em {format(new Date(group.group_updatedat), "dd/MM/yyyy 'às' HH:mm:ss")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Group;
