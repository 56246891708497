

export const saveData = (key, value) => {
    localStorage.setItem(key, value);
};

export const getData = (key) => {
    const data = localStorage.getItem(key);
    if (data) {
        return data;
    }
    return null;
};

export const removeData = (key) => {
    localStorage.removeItem(key);
};

