import React from "react";

const CustomButton = (props) => {
  if (!props.isVisible) {
    return null; // Retorna null se isVisible for falso, fazendo o botão não ser renderizado
  }
  return (
    <button
      style={{
        backgroundColor: props.backgroundColor || "#121212",
        color: props.textColor || "white",
        padding: "5px 20px",
        borderRadius: "10px",
        border: "none",
        cursor: "pointer",
        marginRight: "10px",
      }}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
};

export default CustomButton;
