import React, { useState, useEffect } from "react";
import { Stack, Box } from "@mui/material";
import CustomButton from "./CustomButton";
import { env } from "../env"

const Cards = ({ groups = [], setSelectedGrupo, ads = [] }) => {
  const [itemsPerPage] = useState(12);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsToRender, setItemsToRender] = useState([]);
  const totalPages = Math.ceil(groups.length / itemsPerPage);
  //let itemsToRender = [];
  const insertRandomAds = () => {
    setItemsToRender([]);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    let items = groups.slice(startIndex, endIndex);

    for (let index = 0; index < ads.length; index++) {
      let randomAd = Math.floor(Math.random() * ((ads.length - 1) - 0 + 1)) + 0;
      const ad = ads[randomAd];
      let min = 0;
      let max = items.length - 1;
      let randomIndex = Math.floor(Math.random() * (max - min + 1)) + min;
      const card = {
        group_id: ad.id,
        group_name: "",
        group_description: "",
        group_image: ad.imagem,
        category_description: "",
        group_vip: "0",
        group_invite: ad.url,
        ads: true
      };
      items.splice(randomIndex, 0, card);
    }
    //itemsToRender = items;
    setItemsToRender(items);
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    insertRandomAds();
  }, [currentPage, groups, ads]);

  const renderPagination = () => {
    return (
      <nav aria-label="Page navigation">
        <ul className="pagination flex-wrap">
          <CustomButton onClick={handlePrevious} isVisible={currentPage !== 1}>
            Anterior
          </CustomButton>
          <CustomButton
            onClick={handleNext}
            isVisible={currentPage !== totalPages}
          >
            Próxima
          </CustomButton>
        </ul>
      </nav>
    );
  };

  return (
    <>
      <div className="section-groups row mt-5 pb-3" height="auto !important;">
        {itemsToRender.map((group) => {
          console.log(JSON.stringify(group, undefined, 2));
          return (<div
            className="col-12 col-md-6 col-lg-4 mb-4 col-group"
            key={group.group_id}
          >
            <div className="card group vip">
              {!group.ads && <img
                className="card-img-top"
                alt={group.group_name}
                src={
                  (group.group_image.includes("http") || group.group_image.includes("https")) ?
                    group.group_image :
                    `${env.REACT_APP_IMG_URL}/${group.group_image}`
                }
                loading="lazy"
              />}
              {
                group.ads && <a href={group.group_invite} target="_blank" rel="noopener noreferrer">
                  <img
                    className="card-img-ads"
                    alt={group.group_name}
                    src={
                      (group.group_image.includes("http") || group.group_image.includes("https")) ?
                        group.group_image :
                        `${env.REACT_APP_IMG_URL}/${group.group_image}`
                    }
                    loading="lazy"
                  />
                </a>
              }
              {!group.ads && <div className="card-body">
                <span className="card-category">{group.category_description}</span>
                <h5 className="card-title">{group.group_name}</h5>
                <p className="card-text">{group.group_description}</p>
                <a
                  href="#"
                  onClick={() => setSelectedGrupo(group)}
                  className="btn btn-success btn-block stretched-link font-weight-bold"
                >
                  Ver Grupo
                </a>
              </div>}
              {!group.ads && group.group_vip != 9999999 && (
                <div className="vip-star">
                  <img
                    src="https://gruposwhats.app/images/star.png"
                    height="40"
                    alt="VIP"
                  />
                </div>
              )}
            </div>
          </div>)
        })}
      </div>
      <Stack direction="column">
        <Box
          sx={{
            letterSpacing: "0.025em",
            textAlign: "center",
            fontStyle: "normal",
            zIndex: "20",
          }}
        >
          {renderPagination()}
        </Box>
      </Stack>
    </>
  );
};

export default Cards;
