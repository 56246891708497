import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  CssBaseline,
  Typography,
  Stack,
  IconButton,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material";
import TopBar from "./Components/TopBar";
import Page from "./Page";
import { styled } from "@mui/system";

import { Facebook, Instagram, LinkedIn } from "@mui/icons-material";
import logotpt from "./assets/logo-pt.png";
import { getData, saveData } from "./Services/localStorage";
import { v4 as uuidv4 } from 'uuid';
import FormGroup from "./Components/FormGroup";
import MyGroups from "./Components/MyGroups";
import api from "./Services/api";
import ReactGA from 'react-ga';

const TextTypography = styled(Typography)({
  fontFamily: "Nunito, sans-serif",
  variant: "body1",
  component: "div",
  fontSize: "20px",
  fontWeight: "400",
  lineHeight: "27px",
});

const FooterBottomWave = styled(Box)(({ color }) => ({
  position: "relative",
  height: "150px",
  width: "100%",
  background: color,
  marginTop: "40px",
}));

const FooterTopWave = styled(Box)({
  position: "absolute",
  borderRadius: "100%",
  width: "100%",
  height: "75px",
  backgroundColor: "#fff",
  right: "0px",
  top: "-40px",
  filter: "drop-shadow(11px 14px 17px rgba(37, 56, 92, 0.15))",
});

const theme = createTheme({
  palette: {
    primary: {
      main: "#1EB79F",
      contrastText: "#FFFFFF",
      darker: "#053e85",
    },
    success: {
      main: "#4783F0",
      darker: "#053e85",
    },
    warning: {
      main: "#00ACC1",
      darker: "#053e85",
    },
    danger: {
      main: "#25385C",
      darker: "#053e85",
    },
    secondary: {
      main: "#121212",
      contrastText: "#FFFFFF",
      darker: "#053e85",
    },
  },
});

function App() {
  const [pageVisible, setPageVisible] = useState(true);
  const [formGroup, setFormGroup] = useState(false);
  const [myGroups, setMyGroups] = useState(false);
  const [categorias, setCategorias] = useState([]);
  const [logoFooter, setLogoFooter] = useState(null);
  const [textFooter, setTextFooter] = useState("");
  const [color, setColor] = useState("#ffffff");
  const [title, setTitle] = useState("");

  function handleHidePage() {
    setPageVisible(false);
    setFormGroup(true);
    setMyGroups(false);
  };

  const handleMyGroups = () => {
    setPageVisible(false);
    setMyGroups(true);
    setFormGroup(false);
  };

  useEffect(async () => {
    const response = await api.get("/configs");
    const script = document.createElement('script');
    script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
    script.async = true;
    script.crossOrigin = 'anonymous';
    document.body.appendChild(script);

    const ins = document.createElement('ins');
    ins.className = 'adsbygoogle';
    ins.style.display = "block";
    ins['data-ad-client'] = response.data.configs[0].adsense;
    ins['data-ad-slot'] = "7370094957";
    document.body.appendChild(ins);

    return () => {
      document.body.removeChild(script);
      document.body.removeChild(ins);
    };
  }, []);


  useEffect(() => {
    (async () => {
      try {
        const response = await api.get("/configs");
        if (response.data.configs[0].googleanalyticsid) {
          ReactGA.initialize(response.data.configs[0].googleanalyticsid);
          ReactGA.pageview(window.location.pathname + window.location.search);
        }
        setLogoFooter(`${window.location.origin.replace('https://www.', 'https://app.')}/${response.data.configs[0].logo_footer}`);
        setColor(response.data.configs[0].cor);
        setTextFooter(response.data.configs[0].text_footer);
        setTitle(response.data.configs[0].title_site);
        document.title = response.data.configs[0].title_site;
        console.log(response.data.configs[0]);
        console.log("title", response.data.configs[0].title_site);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  /*useEffect(() => {
    console.log("title", title);
    document.title = title;
  }, [title]);*/

  useEffect(() => {
    const userToken = getData("userToken");
    if (userToken === null || userToken === undefined) {
      saveData("userToken", uuidv4());
    }
  }, []);

  return (
    <>
      <CssBaseline />

      <ThemeProvider theme={theme}>
        <TopBar onHidePage={handleHidePage} onMyGroups={handleMyGroups} color={color} />
        <Container
          sx={{
            backgroundColor: "#ffffff",
            zIndex: "100",
            paddingTop: "0",
          }}
        >
          {pageVisible && !formGroup && !myGroups && <Page TextTypography={TextTypography} setCategorias={setCategorias} />}
          {!pageVisible && formGroup && !myGroups && <FormGroup Categorias={categorias} />}
          {!pageVisible && myGroups && !formGroup && <MyGroups />}
        </Container>

        <FooterBottomWave color={color}>
          <FooterTopWave />
          <Stack
            direction="row"
            justifyContent="space-around"
            alignItems="center"
            sx={{
              color: "#fff",
              paddingTop: "40px",
            }}
          >
            <Stack direction="row">
              <a className="navbar-brand" href="/" title="Grupos">
                {logoFooter && (<img src={logoFooter} alt="" height={60} />)}
              </a>
            </Stack>
            <TextTypography
              sx={{
                marginTop: "100px",
                fontSize: "15px",
                color: "#e0e0e0",
                position: "absolute",
              }}
            >
              {textFooter}
            </TextTypography>

            <Stack direction="row" spacing={2}>
              <IconButton disabled sx={{ color: "#fff" }}>
                <Facebook />
              </IconButton>
              <IconButton disabled sx={{ color: "#fff" }}>
                <Instagram />
              </IconButton>
              <IconButton disabled sx={{ color: "#fff" }}>
                <LinkedIn />
              </IconButton>
            </Stack>
          </Stack>
        </FooterBottomWave>
      </ThemeProvider>
    </>
  );
}

export default App;
