import React, { useEffect, useState } from "react";
import { Container } from "@mui/material";
import logotpt from "../assets/logo-pt.png";
import logosmallpt from "../assets/logo-small-pt.png";
import { makeStyles } from "@mui/styles";
import api from "../Services/api";

const useStyles = makeStyles({
  imageLogo: {
    width: 75,
    height: 75,
    marginTop: "-5px",
    "@media (max-width: 768px)": {
      display: "none",
    },
  },
});


const TopBar = (props) => {
  const classes = useStyles();
  const [myGroups, setMyGroups] = useState([]);
  const [logoHeader, setLogoHeader] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get(`/mygroups/${localStorage.getItem("userToken")}`);
        setMyGroups(response.data.mygroups);
      } catch (error) {
        console.log(error);
      }
      try {
        const configResponse = await api.get('/configs');
        const url_logo = `${window.location.origin.replace('https://www.', 'https://app.')}/${configResponse.data.configs[0].logo_header}`;
        setLogoHeader(url_logo);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  function handleHidePage() {
    props.onHidePage();
  };

  const handleMyGroups = () => {
    props.onMyGroups();
  };

  return (
    <nav className="navbar-dark sticky-top shadow-sm" style={{ backgroundColor: props.color }}>
      <Container>
        <div className="row py-2 align-items-center">
          <div className="col-12 col-lg-6 text-center text-lg-left mb-2 mb-lg-0 d-none d-lg-block">
            <a
              className="navbar-brand"
              href="/"
              title="Grupos"
            >
              {logoHeader && (<img
                src={logoHeader}
                alt="Grupos"
                className="logo"
                height={42}
              />)}
            </a>
          </div>
          <div className="col-12 col-lg-6 text-center text-lg-right d-flex">
            <a
              className="navbar-brand d-lg-none py-0 mr-2 d-flex align-items-center"
              title="Grupos"
              href="/"
            >
              {/*<img
                src={logosmallpt}
                alt="Grupos"
                className="logo"
                width={42}
                height={42}
              />*/}
            </a>
            <a className="btn btn-header btn-send-group mr-1 ml-auto px-1 px-sm-2" href="#" onClick={handleHidePage}>Enviar Grupo</a>
            <a className="btn btn-header btn-view-groups ml-1 px-1 px-sm-2" href="#" onClick={handleMyGroups}>
              <span>
                Meus Grupos
                <span className="badge badge-warning ml-2 badge-count">{myGroups.length}</span>
              </span>
            </a>
          </div>
        </div>
      </Container>
    </nav>
  );
};

export default TopBar;
