import React, { useState, useRef, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from "../Services/api";
import MyCards from "./MyCards";
import { env } from "../env"

const MyGroups = () => {
    const [myGroups, setMyGroups] = useState([]);
    const [selectedGrupo, setSelectedGrupo] = useState(null);

    useEffect(() => {
        (async () => {
            try {
                const response = await api.get(`/mygroups/${localStorage.getItem("userToken")}`);
                setMyGroups(response.data.mygroups);
                console.log(response.data.mygroups);
            } catch (error) {
                console.log(error);
            }
        })();
    }, []);

    const showToastMessage = (message) => {
        toast(message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      };
    
      return (
        <>
          <ToastContainer />
          <h1 className="section-title">Meus Grupos</h1>
          <MyCards groups={myGroups} setSelectedGrupo={setSelectedGrupo} />
        </>
      );

};

export default MyGroups;