import axios from "axios";
import { env } from "../env"

const api = axios.create({
  baseURL: env.REACT_APP_API_URL,
  headers: {
    //"Content-Type": "application/json",
    authorization: `Basic ${env.REACT_APP_API_KEY}`,
  },
});

export default api;
