import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import GroupsIcon from '@mui/icons-material/Groups';
import "../style.css";

const useStyles = makeStyles({
  categoryList: {
    display: "flex",
    flexWrap: "wrap",
  },
  category: {
    marginRight: "10px",
  },
  categoryToggle: {
    fontSize: "20px",
  },
});

const Categories = ({ categories = [], setSelectedCategory }) => {
  const [expanded, setExpanded] = useState(true);
  const [selectedCategories, setCategories] = useState([]);
  const [selectedNameButton, setNameButton] = useState("Mais Categorias");

  useEffect(() => {
    setCategories(categories);
  }, [categories]);

  const toggleExpand = () => {
    setExpanded(!expanded);
    if (expanded) {
      setClassCategories("row row-categories row-categories-open");
      setNameButton("Menos Categorias");
    } else {
      setClassCategories("row row-categories");
      setNameButton("Mais Categorias");
    }
  };

  const toggleSelectCategory = (category) => {
    setSelectedCategory(category);
  };

  const [classCategories, setClassCategories] = useState("row row-categories");

  return (
    <section className="categories bg-white">
      <div className="container position-relative">
        <div className={classCategories}>
          {selectedCategories.map((category) => (
            <div key={category.id} className="col-category">
              <a
                className="category text-center"
                href="#"
                onClick={() => toggleSelectCategory(category.description)}
              >
                <span className="category-logo">
                  {/*<GroupsIcon fontSize="large" />*/}
                  <i class={`${category.image}`} aria-hidden="true"></i>
                </span>
                <span className="category-name">{category.description}</span>
              </a>
            </div>
          ))}
        </div>
        <div className="box-more-categories">
          <button
            onClick={toggleExpand}
            className="btn btn-xs btn-secondary text-uppercase more-categories"
          >
            {selectedNameButton}
          </button>
        </div>
      </div>
    </section>
  );
};

export default Categories;
